/* Container for the whole page */
.report-container {
  display: flex;
  min-height: 100vh; /* Full viewport height */
}

/* Style for the dynamic company overview header */
.main-content h1 {
  font-size: 24px; /* Adjust the size as needed */
  color: #000000; /* You can match this with your theme */
  margin-bottom: -10px; /* Provides spacing between the header and the content below */
  text-align: center; /* Center the header, if it fits your design */
}

.left-column {
  flex: 0 0 530px; /* Adjust width as needed */
  background-color: #271f2e; /* Set the background color to match the report page */
  padding: 20px;
  display: flex;
  flex-direction: column;
}

/* Styling for links within the company overview */
.company-link {
  color: #007bff; /* Set the color to match the submit button */
  text-decoration: none; /* Remove the underline from the link */
}

.company-link:hover {
  color: #0056b3; /* Change the color on hover for better user feedback */
}

/* Style for the "Company Overview" header */
.analysis-response h3:first-of-type {
  font-size: 14px; /* Adjust the font size as needed */
  color: #333; /* Color for the header */
  margin-bottom: 10px; /* Space below the header */
  font-weight: 400; /* Font weight for the header */
  border-bottom: none; /* Remove the border-bottom */
  padding-bottom: 0; /* Remove padding below the header */
}

/* Style for the content under the "Company Overview" header */
.analysis-response ul:not(:first-of-type) {
  font-size: 1.0rem; /* Adjust the font size as needed */
  list-style-type: disc;
  margin-left: 20px;
  padding-left: 0;
}

.analysis-response li {
  margin-bottom: 5px;
  line-height: 1.6;
  color: #333; /* Color for the content text */
}

/* CSS for Analysis Response */
.analysis-response {
  margin: 20px 0;
  padding: 15px;
  background-color: #f8f9fa;
  box-shadow: 0px 0px 10px #017bfe; /* Applies a blue box shadow */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: left;
  color: #007bff;
  margin-bottom: 10px;
}

/* CSS to style the checked slider */
.slider-container input[type="range"].checked {
  background-color: #3498db; /* Blue background color when checked */
  transition: background-color 0.2s; /* Smooth transition */
}

/* Style for the text area itself and job title input */
.text-area-container {
  display: block; /* Changed for better control of layout */
  width: 100%;
  max-width: 800px; /* Consistent with question-container width */
  margin: 20px auto; /* Center and provide spacing */
}

.custom-textarea, .custom-input {
  width: 100%; /* Full width */
  padding: 10px; /* Comfortable padding */
  margin-bottom: 20px; /* Space between elements */
  border: 1px solid #ccc; /* Subtle border */
  border-radius: 4px; /* Rounded corners */
  resize: vertical; /* Allows vertical resizing for textarea */
}

/* Style adjustments for labels */
.text-area-container > label {
  display: block; /* Ensures label is properly spaced */
  margin-bottom: 5px; /* Space between label and input/textarea */
  font-weight: bold; /* Emphasizes the label */
}

/* Main content styling adjustments for alignment and padding */
.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content */
  padding: 20px;
  width: 100%; /* Utilizes full width */
}
/* Main content styling */
.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content */
  padding: 20px;
  width: 100%; /* Utilizes full width */
}

.logo-container {
  display: flex;
  justify-content: center;
  margin: 35px 0;
}

.logo {
  max-width: 100%;
  height: auto;
}

.question-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.question-columns {
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-top: 20px;
}

.column {
  flex: 1;
  margin: 0 10px;
}

.column h3 {
  font-size: 1.2rem;
  color: #333;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.question {
  margin: 10px 0;
}

.question label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.question-description {
  font-style: italic;
  margin-bottom: 5px;
}

.question input[type="range"] {
  width: 100%;
}

.slider-value {
  display: inline-block;
  margin-left: 10px;
  font-size: 1.2rem;
  vertical-align: middle;
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 30px;
  width: 100%;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Styling for the additional information section */
.additional-info {
  margin-top: 20px;
}

.additional-info .question select, 
.additional-info .question textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.additional-info textarea {
  height: 100px;
  resize: vertical;
}

/* Responsive design adjustments */
@media (max-width: 1024px) {
  .left-column, .main-content {
    flex-direction: column;
    align-items: center;
  }

  .left-column {
    width: 100%;
  }

  .text-area-container {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .report-container {
    flex-direction: column;
  }

  .left-column, .main-content {
    padding: 15px;
    flex: 1 0 auto;
  }

  .question-columns {
    flex-direction: column;
  }

  .column {
    margin: 0; /* Adjustments for mobile layout */
  }
}
