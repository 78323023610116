/* Add these styles for the loading spinner */
.Loading-Popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px; /* Adjust size as needed */
  height: 300px; /* Adjust size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Light grey background */
  border-radius: 10px; /* Optional, for rounded corners */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Optional, for shadow */
  z-index: 1000;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #017bfe; /* Blue color for the spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .Loading-Popup {
    display: none; /* Hide the popup on mobile */
  }
}
