/* reportpage.css */
.report-container {
  display: flex;
  min-height: 100vh; /* Full viewport height */
}

.left-column {
  flex: 0 0 530px; /* Same width as in QuestionPage */
  background-color: #271f2e; /* Same background color */
  padding: 20px;
  display: flex;
  flex-direction: column;
}

/* Ensure consistency in branding styles */
.oppgrader-title {
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
}

.placeholder-text {
  font-family: 'Open Sans', sans-serif;
  color: #d3d3d3;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
  margin-top: 20px;
  padding: 0 30px;
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Styles for Gauge Chart and other main content elements */
/* ... Add your specific styles here ... */

/* Adjust sidebar width and main content grid layout on smaller screens */
@media (max-width: 768px) {
  .report-container {
    flex-direction: column;
  }

  .main-content {
    order: 1; /* Adjust the order only if necessary */
  }

  .left-column {
    order: 0; /* Keep the left column first */
  }

  .cards-container {
    grid-template-columns: 1fr;
  }
}
