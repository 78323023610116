.App {
  text-align: center;
  /* Replace 'Arial, sans-serif' with the font from the Fundz logo if different */
  font-family: 'FontName', sans-serif;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background-color: #fff; /* or another color based on your design aesthetic */
}

.logo-container {
  margin-bottom: 30px;
}

.logo {
  max-width: 150px; /* Adjust based on the size of your logo */
  height: auto;
}

/* Other styles remain unchanged */

.title {
  font-size: 4rem; /* Increase the font size */
  color: #000000; /* Navy blue color */
  margin: 0 0 0.5rem; /* Adjust margins as needed */
  position: relative; /* To enable repositioning */
  top: -20px; /* Move it higher on the page, adjust the value as needed */
}

/* Add this if you don't have it already to ensure the trademark symbol is styled appropriately */
.title .trademark {
  font-size: 0.5em; /* Smaller size for the trademark symbol */
  vertical-align: super; /* Raises the symbol to the top of the line */
}

.privacy-note {
  font-size: 14px;
  color: #999;
  margin-top: 20px;
  text-align: center; /* Center the privacy policy statement */
  margin-left: 20rem;
  margin-right: 20rem;
}

.subtitle {
  font-size: 1rem;
  color: #666; /* Adjust the color to match your brand */
  margin-bottom: 2rem;
}

.grader-form {
  width: 100%;
  max-width: 400px; /* Adjust as needed */
  margin: 0 auto;
}

.input-field {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.submit-btn {
  padding: 12px;
  margin-top: 10px;
  background-color: #007bff; /* Adjust to your brand color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  transition: background-color 0.3s; /* Retain the transition for background color */
}

.submit-btn:hover,
.submit-btn:active {
  background-color: #0056b3; /* Slightly darker blue when hovered or clicked */
}

.error-message {
  color: red;
  margin-top: 10px;
}

/* Retain the existing responsive design rules */
@media (max-width: 600px) {
  .home-container {
    margin-top: 0;
    height: auto;
  }

  .logo {
    max-width: 80%;
  }

  .url-input {
    width: calc(100% - 24px);
  }
}
